import api from "@/api";

const admin = {
  list(params) {
    return api.get(`/admin`, {
      params: params,
    });
  },
  create(data) {
    return api.post(`/admin`, data);
  },
  get(pk) {
    return api.get(`/admin/${pk}`);
  },
  update(pk, data) {
    return api.patch(`/admin/${pk}`, data);
  },
  changeState(pk, data) {
    return api.patch(`/admin/${pk}/state`, data);
  },
  changePermission(pk, data) {
    return api.patch(`/admin/${pk}/permission`, data);
  },
  destroy(pk) {
    return api.delete(`/admin/${pk}`);
  },
};

export default admin;
