<template>
  <div id="admin" v-loading="loading">
    <div class="cm-content">
      <h-admin @add="handleAdd"></h-admin>
      <br />
      <el-table :data="data">
        <el-table-column label="编号" prop="pk"> </el-table-column>
        <el-table-column label="用户名" prop="username"> </el-table-column>
        <el-table-column label="是否为超管">
          <template slot-scope="scope">
            {{ scope.row.is_super | formatIsSuper }}
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            {{ scope.row.state | formatState }}
          </template>
        </el-table-column>
        <el-table-column label="时间">
          <template slot-scope="scope">
            {{ scope.row.created_at | formatDateTime }}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.row.pk)">
              编辑
            </el-button>
            <el-button
              v-if="scope.row.state === -1"
              size="mini"
              type="primary"
              @click="handleChangeState(scope.row.pk, { state: 1 })"
            >
              启用
            </el-button>
            <el-button
              v-if="scope.row.state === 1"
              size="mini"
              type="warning"
              @click="handleChangeState(scope.row.pk, { state: -1 })"
              :disabled="scope.row.is_super === 1"
            >
              禁用
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDestroy(scope.row.pk)"
              :disabled="scope.row.is_super === 1"
            >
              删除
            </el-button>
            <!-- <el-button
              size="mini"
              @click="handleSetPermission(scope.row.id, scope.row.permissions)"
              :disabled="scope.row.is_super === 1"
            >
              设置权限
            </el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <br />
      <el-row type="flex" justify="center">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="10"
          :total="total"
        >
        </el-pagination>
      </el-row>
      <d-admin ref="dialog" @change="handleDialogChange"></d-admin>
      <!-- <d-admin-permission ref="dialogPermission"></d-admin-permission> -->
    </div>
  </div>
</template>

<script>
import HAdmin from "@/components/headers/HAdmin";
import DAdmin from "@/components/dialogs/DAdmin";
// import DAdminPermission from "@/components/dialogs/DAdminPermission";

import admin from "@/api/admin";

export default {
  name: "admin",
  data() {
    return {
      loading: false,
      data: [],
      params: {},
      total: 0,
      currentPage: 1,
    };
  },
  created() {
    this.handleInit(this.$route);
    this.handleList(this.params);
  },
  components: {
    HAdmin,
    DAdmin,
  },
  methods: {
    handleInit(route) {
      if (route.query.page) {
        this.params["page"] = route.query.page;
      }
    },
    handleList(params) {
      this.loading = true;
      admin.list(params).then((res) => {
        this.data = res.results;
        this.total = res.count;
        this.loading = false;
        this.currentPage = parseInt(this.$route.query.page);
      });
    },
    handleAdd() {
      this.$refs.dialog.handleAdd();
    },
    handleEdit(pk) {
      this.$refs.dialog.handleEdit(pk);
    },
    handleChangeState(pk, data) {
      this.$confirm("是否修改？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          admin
            .changeState(pk, data)
            .then(() => {
              this.handleList(this.params);
            })
            .catch((err) => {
              this.$message({
                type: "warning",
                message: err.message,
              });
            });
        })
        .catch(() => {});
    },
    handleDestroy(pk) {
      this.$confirm("是否删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          admin
            .destroy(pk)
            .then(() => {
              this.handleList();
            })
            .catch((err) => {
              this.$message({
                type: "warning",
                message: err.message,
              });
            });
        })
        .catch(() => {});
    },
    handleSetPermission(pk, permissions) {
      this.$refs.dialogPermission.handleShow(pk, permissions);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.params["page"] = val;
      let params = Object.assign({}, this.params);
      this.$router
        .push({
          name: "admin",
          query: params,
        })
        .catch(() => {});
    },
    handleSearch(val) {
      this.currentPage = 1;
      this.params = val;
      this.$router
        .push({
          name: "admin",
          query: val,
        })
        .catch(() => {});
    },
    handleDialogChange(val) {
      if (val) {
        this.handleList(this.params);
      } else {
        this.handleList();
      }
    },
  },
  watch: {
    $route: {
      deep: true,
      handler: function (val) {
        if (val.name === "admin") {
          this.handleInit(val);
          this.handleList(this.params);
        }
      },
    },
  },
};
</script>

<style lang="stylus">
#admin {
  display: flex;
  height: 100%;
}
</style>
