<template>
  <div id="d-admin">
    <el-dialog
      :title="title"
      :visible.sync="visible"
      @closed="handleClosed"
      width="400px"
    >
      <el-form
        :model="form"
        ref="form"
        label-width="80px"
        label-position="left"
      >
        <el-form-item label="账号" prop="username" required>
          <el-input
            size="small"
            type="text"
            v-model="form.username"
            placeholder="账号"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="密码"
          prop="password"
          v-if="action === 'create'"
          required
        >
          <el-input
            size="small"
            type="password"
            v-model="form.password"
            placeholder="密码"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="handleCancel">取 消</el-button>
        <el-button size="small" type="primary" @click="handleOk('form')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import admin from "@/api/admin";

export default {
  name: "d-admin",
  data() {
    return {
      visible: false,
      form: {
        username: "",
        password: "",
        is_admin: -1,
      },
      title: "添加人员",
      action: "create",
    };
  },
  methods: {
    handleAdd() {
      this.visible = true;
      this.action = "create";
      this.title = "添加人员";
    },
    handleEdit(pk) {
      this.action = "edit";
      this.title = "编辑人员";
      admin.get(pk).then((res) => {
        this.visible = true;
        this.form = res.results;
      });
    },
    handleOk(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.action === "create") {
            let data = this.form;
            admin
              .create(data)
              .then(() => {
                this.visible = false;
                this.$message({
                  type: "success",
                  message: "添加成功！",
                });
                this.$emit("change", false);
              })
              .catch((err) => {
                this.$message({
                  type: "warning",
                  message: err.message,
                });
              });
          } else {
            let pk = this.form.pk;
            let data = this.form;
            admin
              .update(pk, data)
              .then(() => {
                this.visible = false;
                this.$message({
                  type: "success",
                  message: "编辑成功！",
                });
                this.$emit("change", true);
              })
              .catch((err) => {
                this.$message({
                  type: "warning",
                  message: err.message,
                });
              });
          }
        }
      });
    },
    handleCancel() {
      this.visible = false;
    },
    handleClosed() {
      this.form = {
        username: "",
        password: "",
        is_admin: -1,
      };
      this.$refs.form.resetFields();
    },
  },
};
</script>
